import React, {useEffect} from "react";
import './index.less'
import {DrawerProps} from "@/components/Drawer/interface";

const ZDrawer = (props: DrawerProps) => {
    const {
        mask = true,
        maskClosable = true,
        title = '方案详情',
        width = 450,
        visible = false,
        children,
        onClose
    } = props

    // 点击遮罩层
    const onMask = () => {
        if (mask && maskClosable) {
            onClose?.()
        }
    }

    useEffect(() => {
        const body: any = document.querySelector('body')
        if (visible) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = 'auto'
        }
    }, [visible])
    return (
        <>
            {
                visible
                &&
                <div className='zc-drawer-wrapper' style={{width: mask && '100%' || width}} onClick={onMask}>
                    <div className='zc-drawer-content-wrapper' onClick={(e: any) => e.stopPropagation()}
                         style={{width}}>
                        <div className="zc-drawer-content">
                            <div className="zc-drawer-wrapper-body">
                                <div className="zc-drawer-header">
                                    <div className='zc-drawer-title'>{title}</div>
                                    <div className='zc-drawer-close' onClick={onClose}>×</div>
                                </div>
                                <div className="zc-drawer-body">
                                    {children}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ZDrawer

